<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <div
            class="d-flex d-lg-block flex-column justify-content-center align-items-center"
          >
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="width: 50px"
              class="img-fluid"
            />
            <h3 class="text-center">{{ appName }}</h3>
          </div>
        </b-link>

        <b-card-title class="mb-1"> Reset Password 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Your new password must be different from previously used passwords
        </b-card-text>

        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="resetPass"
          >
            <b-form-group label="New Password" label-for="reset-password-new">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button block type="submit" variant="primary">
              Set New Password
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import { $themeConfig } from "@themeConfig";
import router from "@/router";
import { getAccessTokenFromCookie } from "@/auth/utils";
export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      userEmail: "",
      cPassword: "",
      password: "",
      hash: "",
      password1FieldType: "password",
      password2FieldType: "password",
      appLogoImage,
      appName,
    };
  },
  mounted() {
    this.hash = this.$route.query.hash;
    if (this.hash) {
      this.getMail(this.hash);
    } else {
      this.$router.push({ name: "auth-login" });
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    resetPass() {
      try {
        if (this.password !== this.cPassword) {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Password and confirmation password don't match"
          );
          return;
        }
        if (this.password.length < 8) {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Password must contain atleast 8 Characters"
          );
          return;
        }
        const formData = {
          email: this.userEmail,
          password: this.password,
          password_confirmation: this.cPassword,
          token: this.hash,
        };

        axios.post("/reset-password", formData).then(({ data }) => {
          if (data.success) {
            this.showToast("success", "SaveIcon", "Success", data.message);
            router.push({ name: "auth-login" });
          } else {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              data.message
            );
          }
        });
      } catch (error) {
        this.showToast("danger", "AlertTriangleIcon", "Error", error);
      }
    },
    getMail(token) {
      axios
        .post("/get-mail-by-token", {
          token: token,
        })
        .then(({ data }) => {
          if (data.success) {
            this.userEmail = data.email;
          } else {
            router.push({ name: "error-404" });
          }
        })
        .catch((err) => {
          // this.showToast(
          //   "danger",
          //   "AlertTriangleIcon",
          //   "Error",
          //   err.message
          // );
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    const data = getAccessTokenFromCookie();
    if (data) {
      const role = data[2].role;
      if (role === "admin") {
        next("/admin-dash");
      } else {
        next("dashboard");
      }
    } else {
      next(true);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
